let overlay = null;
let height = null;
let lastY = 0;

const setHeight = function () {
	if (window.innerHeight > 736) {
		overlay.style.height = 736 + 'px';
	} else {
		overlay.style.height = '100vh';
	}
};

const animate = function () {
	const btn = document.getElementById('appIntroOverlay__arrow-down');
	if (!btn) return;

	btn.addEventListener('click', () => {
		TweenLite.to(overlay, 0.5, {
			css: { transform: 'translateY(' + (0 - height - 10) + 'px)' },
			ease: Power2.easeOut,
		});
		document.body.classList.remove('lock-scroll');
	});
};

const draggable = function () {
	Draggable.create('#appIntroOverlay', {
		type: 'y',
		inertia: false,
		// Set the Direction to swipe upwards only
		liveSnap: {
			y: function (y) {
				if (y < lastY) {
					lastY = y;
				}
				return lastY;
			},
		},
		allowNativeTouchScrolling: false,
		// Determine if swipe was at least half the screen
		onRelease: function () {
			var currentY = this.y;
			//console.log(this.y);
			// If less than half the screen -> send them back down
			if (currentY > 0 - 40) {
				TweenLite.set(overlay, { css: { transform: 'translateY(' + currentY + 'px)' } });
				TweenLite.to(overlay, 0.5, {
					css: { transform: 'translateY(0px)' },
					ease: Power2.easeOut,
				});
				lastY = 0;
			}
			// The user intended to swipe all the way -> lets remove the overlay
			if (currentY < 0 - 40) {
				TweenLite.set(overlay, { css: { transform: 'translateY(' + currentY + 'px)' } });
				TweenLite.to(overlay, 0.5, {
					css: { transform: 'translateY(' + (0 - height - 10) + 'px)' },
					ease: Power2.easeOut,
				});
				document.body.classList.remove('lock-scroll');
				_load_video();
				_load_reviews();
			}
		},
	});
};

export const Overlay = function () {
	if (!document.getElementById('appIntroOverlay')) return;

	overlay = document.getElementById('appIntroOverlay');
	height = overlay.offsetHeight;

	if (overlay.className.indexOf('app__product-not-found') < 0) {
		setHeight();
		animate();
		draggable();
	}

	window.addEventListener('click', () => setHeight());
};
