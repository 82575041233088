const Skull = function () {
	return (
		<svg viewBox="0 0 64 77">
			<g transform="matrix(1,0,0,1,-15498.7,-519.554)">
				<g transform="matrix(2.66667,0,0,2.66667,11520,0)">
					<g transform="matrix(1,0,0,1,1503.76,212.509)">
						<path d="M0,-6.769L-2.531,-6.769C-4.477,-8.327 -2.822,-11.443 -2.822,-11.443L-0.584,-11.735L2.336,-10.371C2.82,-7.548 0,-6.769 0,-6.769M-2.174,4.97L-2.694,3.941L-2.573,3.196L-2.736,3.277L-3.601,3.2L-3.601,1.795L-2.86,0.703L-2.305,1.639L-2.422,3.062L-2.11,2.909L-1.59,3.277L-1.524,4.603L-2.174,4.97ZM-3.081,4.823L-3.536,3.866L-3.536,3.351L-2.889,3.351C-2.889,3.351 -3.212,3.941 -2.694,4.676L-3.081,4.823ZM-4.379,4.823L-4.379,3.646L-3.796,3.646L-3.665,4.749L-4.379,4.823ZM-5.482,5.117C-5.482,5.117 -5.937,4.456 -5.873,3.866C-5.807,3.277 -5.417,3.866 -5.417,3.866L-5.222,5.044L-5.482,5.117ZM-6.26,5.485L-6.586,4.455L-6.392,4.087L-6.067,4.161L-5.741,5.337L-6.26,5.485ZM-6.91,2.208L-6.455,1.66L-6.001,1.816L-5.937,3.543L-6.65,4.013C-6.974,3.622 -6.91,2.208 -6.91,2.208M-3.796,3.416L-5.482,3.572L-5.482,1.692L-4.963,1.145L-3.991,1.692L-3.796,3.416ZM-6.163,-9.188L-3.925,-6.265L-3.343,-5.973L-4.606,-6.314L-4.12,-5.488L-3.536,-3.734L-4.169,-2.419L-4.655,-2.419L-4.801,-3.297L-5.289,-2.761L-5.968,-2.712L-5.823,-1.786L-6.65,-2.079L-6.699,-2.954L-6.894,-4.611L-7.234,-8.117L-6.163,-9.188ZM-7.492,-5.113C-8.954,-3.264 -10.609,-3.459 -10.609,-3.459L-10.511,-4.627C-11.387,-5.212 -10.997,-7.157 -10.997,-7.157L-9.147,-9.107L-8.175,-9.107L-7.397,-8.619L-7.787,-7.743L-7.492,-5.113ZM10.699,-12.081L9.625,-13.506C9.625,-13.506 5.894,-16.871 1.526,-17.676C1.526,-17.676 -3.78,-17.519 -4.718,-17.167C-5.66,-16.816 -11.298,-14.464 -11.016,-8.492L-11.747,-6.284L-11.204,-4.085L-11.063,-0.689L-10.477,1.345C-10.477,1.345 -9.086,1.156 -7.843,2.16C-7.843,2.16 -7.784,4.666 -6.769,5.922L-7.061,9.382L-5.696,10.907L-3.499,10.213L0.165,9.821C0.165,9.821 3.74,7.071 5.431,6.634L6.697,6.024L7.185,4.295L8.455,4.194L11.909,-1.441C11.909,-1.441 12.497,-8.425 10.699,-12.081"></path>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Skull;
