const Mailgun = {
	send: async function (data) {
		const res = await fetch('/api/mailgun/send', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data),
		});

		return await res.json();
	},
};

export const API = {
	Mailgun,
};
