const Close = function () {
	return (
		<svg className="menu-btn__close" viewBox="0 0 451 451">
			<g transform="matrix(1,0,0,1,50.2494,50.2494)">
				<g transform="matrix(2.94628,0,0,2.94628,-511.482,1.16967)">
					<g transform="matrix(1.41421,-1.41421,-1.41421,-1.41421,233,143)">
						<path d="M-12.302,29.699L71.699,29.699"></path>
					</g>
					<g transform="matrix(1.41421,1.41421,1.41421,-1.41421,233,-25)">
						<path d="M-12.302,-29.699L71.699,-29.699"></path>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Close;
