const pentagonPerformance = {
	1: '91.4917,61.6426',
	2: '91.4917,49.6426',
	3: '91.4917,36.6426',
	4: '91.4917,24.6426',
	5: '91.4917,11.6426',
};
const pentagonAllMountain = {
	1: '113.4917,81.6426',
	2: '124.4917,78.6426',
	3: '135.4917,75.6426',
	4: '146.4917,73.6426',
	5: '157.4917,70.6426',
};
const pentagonPowder = {
	1: '106.4917,99.6426',
	2: '112.4917,108.6426',
	3: '119.4917,118.6426',
	4: '125.4917,127.6426',
	5: '132.4917,136.6426',
};
const pentagonUrban = {
	1: '76.4917,99.6426',
	2: '70.4917,108.6426',
	3: '64.4917,118.6426',
	4: '57.4917,127.6426',
	5: '51.4917,136.6426',
};
const pentagonPark = {
	1: '70.4917,81.6426',
	2: '59.4917,78.6426',
	3: '48.4917,75.6426',
	4: '37.4917,73.6426',
	5: '26.4917,70.6426',
};

export const PentaGraphic = function () {
	const riderTypeIcon = document.getElementById('riderTypeIcon');
	const riderType = document.getElementById('rider-penta-graphic');
	if (!riderTypeIcon || !riderType || (riderType & riderTypeIcon && !riderType.dataset)) return;

	const performance = pentagonPerformance[riderType.dataset.performance];
	const allMountain = pentagonAllMountain[riderType.dataset.allmountain];
	const powder = pentagonPowder[riderType.dataset.powder];
	const urban = pentagonUrban[riderType.dataset.urban];
	const park = pentagonPark[riderType.dataset.park];
	const setPoly = performance + ' ' + allMountain + ' ' + powder + ' ' + urban + ' ' + park;

	gsap.to(riderTypeIcon, 5, {
		morphSVG: setPoly,
		rotation: 0,
		scale: 1,
		fill: '#f8da49',
		ease: Elastic.easeOut.config(1, 0.75),
	});
};
