import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import Close from '../icons/Close.js';
import { asyncTimeout, getCSSVariable } from '../../utils.js';

const delay = parseInt(getCSSVariable('--animate-duration'));

const Modal = function (props) {
  const refModal = useRef(null);
  const refDialog = useRef(null);

  const escapeKeyClose = function (e) {
    if (e.keyCode === 27) props.closeModal();
  };

  useEffect(() => {
    (async () => {
      const modal = refModal.current;
      const dialog = refDialog.current;

      if (props.active && modal.className.indexOf('animate__fadeIn') === -1) {
        modal.classList.add('animate__fadeIn');
        await asyncTimeout(delay);
        dialog.classList.add('animate__fadeInDown');
        document.addEventListener('keyup', escapeKeyClose);
        return;
      }

      if (!props.active && modal.className.indexOf('animate__fadeIn') > -1) {
        dialog.classList.add('animate__fadeOutUp');
        await asyncTimeout(delay);
        modal.classList.add('animate__fadeOut');
        await asyncTimeout(delay);
        modal.classList.remove('animate__fadeIn', 'animate__fadeOut');
        dialog.classList.remove('animate__fadeInDown', 'animate__fadeOutUp');
        document.removeEventListener('keyup', escapeKeyClose);
      }
    })();
  }, [props.active]);

  return (
    <div ref={refModal} className="modal animate__animated">
      <div ref={refDialog} className="modal__dialog container container--padding animate__animated">
        <div className="modal__header row end-xs">
          <button type="button" onClick={() => props.closeModal()}>
            <Close />
          </button>
        </div>
        <div className="modal__body">{props.children}</div>
      </div>
    </div>
  );
};

export default Modal;
