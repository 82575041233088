import React, { useState, useEffect, useRef, Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import BeatLoader from 'react-spinners/BeatLoader';
import { toast, getCSSVariable } from '../../utils.js';

const primary = getCSSVariable('--theme-primary');
const dark = getCSSVariable('--theme-dark');
const light = getCSSVariable('--theme-light');

const ProductionChart = function () {
	const statsRef = useRef(null);
	const [stats, setStats] = useState(null);

	const createChart = function (data, season) {
		const config = {
			type: 'doughnut',
			data: {
				labels: ['UUIDs: ' + data.uuid_count, 'Registered: ' + data.snowboards_registered],
				datasets: [
					{
						label: ['UUIDs', 'Registered'],
						data: [data.uuid_count, data.snowboards_registered],
						backgroundColor: ['#35a2eb', '#c9cbcf']
					}
				]
			},
			options: {
				responsive: true,
				plugins: {
					legend: {
						position: 'top',
						labels: {
							color: 'white'
						}
					},
					title: {
						display: true,
						color: '#ffffff',
						text: 'Snowboards With NFC',
						align: 'start',
						padding: 15,
						font: { size: 22 }
					}
				}
			}
		};

		const chart = new Chart(statsRef.current, config);
	};

	// Get init stats and sort by year
	useEffect(() => {
		(async () => {
			const date = new Date();
			const year = date.getFullYear();

			let season = null;
			if (year % 2 === 0) {
				season = year + ' / ' + (year + 1);
			} else {
				season = year - 1 + ' / ' + year;
			}

			if (!season) return;

			const raw = await fetch('/api/stats/registered-boards?season=' + season);
			if (!raw) return;
			const res = await raw.json();

			if (res && res.error) {
				return toast('error', res.error);
			}

			if (res && res.data) {
				setStats(res.data);
				createChart(res.data, season);
			}
		})();
	}, []);
	return (
		<Fragment>
			{!stats && <BeatLoader color="#000" loading={true} size={15} aria-label="Loading Spinner" />}
			<canvas width="600" height="300" ref={statsRef}></canvas>
		</Fragment>
	);
};

export default ProductionChart;
