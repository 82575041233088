import React, { useState, useEffect, useRef, Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import Navigation from '../../components/Navigation';
import Table from '../../components/Table';
import { toast } from '../../utils.js';
import AddIcon from '../../components/icons/Add.js';
import Modal from '../../components/Modal';

// Get Product Codes
const Wrapper = function () {
  const refForm = useRef(null);
  const refCode = useRef(null);
  const refDescription = useRef(null);
  const [codes, setCodes] = useState(null);
  const [code, setCode] = useState({ id: null, prod_code: '', prod_desc: '' });

  useEffect(() => {
    (async () => {
      const raw = await fetch('/api/product/codes');
      if (!raw) return;

      const res = await raw.json();

      if (res && res.error) {
        return toast('error', res.error);
      }

      if (res && res.data) {
        let rows = [];
        const data = res.data.map((item) => ({
          data: Object.values(item),
        }));

        res.data.forEach((item) => {
          rows.push(createTableRow(item));
        });

        setCodes(rows);
      }
    })();
  }, []);

  const createTableRow = function (item) {
    return {
      data: [
        { key: item.id },
        item.prod_code,
        item.prod_desc,
        {
          button: {
            text: 'Edit',
            callback: () => setCode(item),
          },
        },
      ],
    };
  };

  const setField = function (value, field) {
    const state = Object.assign({}, code);
    state[field] = value;
    setCode(state);
  };

  const submitProductCode = async function (e) {
    if (e) e.preventDefault();

    const data = Object.assign({}, code);

    if (data.id === 'new') delete data.id;

    const raw = await fetch('/api/product/code', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    setCode({ id: null, prod_code: '', prod_desc: '' });
    refCode.value = '';
    refDescription.value = '';

    if (!raw) return;

    const res = await raw.json();

    if (res && res.error) {
      return toast('error', res.error);
    }

    if (res && res.data && !data.id) {
      const code = createTableRow(res.data);

      const state = Object.assign([], codes);
      state.splice(0, 0, code);
      setCodes(state);
    }
  };

  const updateProductCode = async function (e) {
    if (e) e.preventDefault();

    const data = Object.assign({}, code);

    const raw = await fetch('/api/product/code/' + data.id, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    setCode({ id: null, prod_code: '', prod_desc: '' });
    refCode.value = '';
    refDescription.value = '';

    if (!raw) return;

    const res = await raw.json();

    if (res && res.error) {
      return toast('error', res.error);
    }

    if (res && res.data && data.id) {
      const state = Object.assign([], codes);

      const _state = state.map((code, index) => {
        if (code.data[0].key === res.data.id) {
          return createTableRow(res.data);
        }
        return code;
      });

      setCodes(_state);
    }
  };

  const closeModal = function () {
    setCode({ id: null, prod_code: '', prod_desc: '' });
    refCode.value = '';
    refDescription.value = '';
  };

  return (
    <Fragment>
      <section className="container container--padding container--lg admin__tabs">
        <div className="row">
          <button
            className="btn btn--rounded btn--black"
            onClick={() => setCode({ ...code, id: 'new' })}
          >
            <span>New</span>
            <AddIcon />
          </button>
        </div>
      </section>
      <section className="admin__codes">
        <div className="container container--padding container--lg">
          <Table withFilter={true} headers={['Product Code', 'Product Desc', '']} data={codes} />
        </div>
        <Modal active={code.id ? true : false} closeModal={closeModal}>
          <form
            ref={refForm}
            className="form"
            onSubmit={(e) => {
              if (!code.id || (code.id && code.id === 'new')) submitProductCode(e);
              if (code.id && code.id !== 'new') updateProductCode(e);
            }}
          >
            <div className="row">
              <div className="col-xs-12">
                <h3>{code.id && code.id !== 'new' ? 'Edit Code' : 'New Code'}</h3>
              </div>
            </div>
            <div className="row">
              <div className="form__input col-xs-12 col-md-5">
                <label>Code:</label>
                <input
                  ref={refCode}
                  className="form-control"
                  type="text"
                  defaultValue={code.prod_code}
                  onChange={(e) => setField(e.target.value, 'prod_code')}
                  required
                />
              </div>
              <div className="form__input col-xs-12 col-md-5 col-md-offset-2">
                <label>Description:</label>
                <input
                  ref={refDescription}
                  className="form-control"
                  type="textarea"
                  onChange={(e) => setField(e.target.value, 'prod_desc')}
                  defaultValue={code.prod_desc}
                  required
                />
              </div>
            </div>
            <div className="row end-xs">
              <div className="col-xs-12">
                <button type="submit" className="btn">
                  {code.id && code.id !== 'new' ? 'Update' : 'Save'}
                </button>
              </div>
            </div>
          </form>
        </Modal>
      </section>
    </Fragment>
  );
};

const AdminRegistrations = function () {
  const root = document.getElementById('admin-product-codes');
  if (!root) return;

  const app = ReactDOM.createRoot(document.getElementById('admin-product-codes'));

  app.render(
    <Fragment>
      <Navigation />
      <Wrapper />
    </Fragment>
  );
};

export default AdminRegistrations;
