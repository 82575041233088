import React, { useState, useEffect, Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import Navigation from '../../components/Navigation';
import Table from '../../components/Table';
import { toast } from '../../utils.js';

// Get Registrations
const Wrapper = function () {
  const [registrations, setRegistrations] = useState(null);

  useEffect(() => {
    (async () => {
      const raw = await fetch('/api/registrations');
      if (!raw) return;

      const res = await raw.json();

      if (res && res.error) {
        return toast('error', res.error);
      }

      if (res && res.data) {
        let rows = [];
        const data = res.data.map((item) => ({
          data: Object.values(item),
        }));

        res.data.forEach((item) => {
          rows.push({
            data: [
              { key: item.id },
              item.email,
              item.fullname,
              item.shop_name,
              item.purchase_date,
              item.nfc_uid,
              item.prod_code,
              item.prod_desc,
              {
                button: {
                  text: 'Delete',
                  callback: async () => {
                    const confirm = window.confirm('Are you sure you want to delete?');

                    if (!confirm) return;

                    const element = document.getElementById(item.id);
                    if (element) element.remove();

                    const res = await fetch('/api/registrations?id=' + item.id, {
                      method: 'DELETE',
                    });

                    if (!res && res && !res.ok) {
                      return toast('error', 'Could not delete record');
                    }
                  },
                },
              },
            ],
          });
        });

        setRegistrations(rows);
      }
    })();
  }, []);

  return (
    <section className="admin__registration">
      <div className="container container--padding container--lg">
        <Table
          withFilter={true}
          headers={[
            'Email',
            'Name',
            'Shop Name',
            'Purchase Date',
            'NFC UID',
            'Product Code',
            'Product Desc',
            '',
          ]}
          data={registrations}
        />
      </div>
    </section>
  );
};

const AdminRegistrations = function () {
  const root = document.getElementById('admin-registrations');
  if (!root) return;

  const app = ReactDOM.createRoot(document.getElementById('admin-registrations'));

  app.render(
    <Fragment>
      <Navigation />
      <Wrapper />
    </Fragment>
  );
};

export default AdminRegistrations;
