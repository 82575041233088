const Capita = function () {
	return (
		<svg viewBox="0 0 804.61 175.72">
			<title>capita-app-logo</title>
			<g>
				<path
					d="M130.08,175.72H64.73Q33.94,175.72,16,149q-16-24-16-59.24,0-35.77,16.32-61Q34.37.86,64.73.86h85.49v37h-79Q56.86,37.81,48.69,53q-7.3,13.59-7.3,33.61,0,20.6,7.59,35.18,8.3,16,22.19,16h71.31Z"
					fill="#fff"
				/>
				<path
					d="M322.45,175.72H281.06L235.38,41.1c-.29-1.43-1.29-2.15-3-2.15a3.8,3.8,0,0,0-3.58,2.15L184.11,175.72h-42q3.58-12.47,15.4-49.55t23-70.18Q193,19.2,195.71,15q5.16-7.3,15-11.17a61.35,61.35,0,0,1,42.89,0q9.81,3.87,15,11.17,3.15,4.29,15.47,40.67Q291.66,78,306.7,125.88q9.15,29.07,15.75,49.84"
					fill="#fff"
				/>
				<path
					d="M429.12,106.69H391.89q-14.33,0-20.48,9.6-4.16,6.58-4.16,16.46v43H329.59v-58q0-20.91,10.31-33.8,12-15,35.37-15h47.4c4.4,0,7.78-1.49,10.17-4.49a16.49,16.49,0,0,0,3.58-10.62,17.31,17.31,0,0,0-3.43-11,12.39,12.39,0,0,0-10.32-5H329.59V.86h99.53q22.33,0,35.37,16.45,11.75,14.86,11.74,36.46T464.49,90.1q-13,16.59-35.37,16.59"
					fill="#fff"
				/>
				<polygon
					points="525.46 175.72 486.8 175.72 486.8 65.22 525.46 49.72 525.46 175.72"
					fill="#fff"
				/>
				<polygon
					points="525.46 37.77 486.8 53.26 486.8 0.92 525.46 0.92 525.46 37.77"
					fill="#fff"
				/>
				<polygon
					points="654.39 37.81 614.86 37.81 614.86 175.72 576.63 175.72 576.63 37.81 537.25 37.81 537.25 0.86 654.39 0.86 654.39 37.81"
					fill="#fff"
				/>
				<path
					d="M804.61,175.72H763.22L717.54,41.1c-.29-1.43-1.29-2.15-3-2.15A3.79,3.79,0,0,0,711,41.1L666.27,175.72h-42q3.57-12.47,15.39-49.55t23-70.18Q675.16,19.2,677.87,15q5.14-7.3,15-11.17a61.38,61.38,0,0,1,42.9,0q9.81,3.87,15,11.17,3.15,4.29,15.47,40.67,7.59,22.33,22.62,70.17Q798,155,804.61,175.72"
					fill="#fff"
				/>
			</g>
		</svg>
	);
};

export default Capita;
