const Add = function () {
	return (
		<svg viewBox="0 0 490 490">
			<polygon
				points="222.031,490 267.969,490 267.969,267.969 490,267.969 490,222.031 267.969,222.031 267.969,0 222.031,0 
	222.031,222.031 0,222.031 0,267.969 222.031,267.969 "
			/>
		</svg>
	);
};

export default Add;
