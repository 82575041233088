export const Video = function () {
	const wrapper = document.getElementById('video-wrapper');

	if (!wrapper) return;

	const src = wrapper.getAttribute('data-vidsrc');
	const thumbnail = document.getElementById('video-thumb');

	if (!wrapper) return;

	thumbnail.style.cssText = 'z-index: -1; opacity: 0;';

	wrapper.innerHTML += `<iframe src="${src}" frameborder="0" allowfullscreen=""></iframe>`;
};
