const time = function () {
	const el = document.getElementById('theTime');
	if (!el) return;

	const _getTime = () => {
		const date = new Date();
		let time = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
		time = time.replace(' AM', '');
		time = time.replace(' PM', '');
		el.innerHTML = time;
	};

	setInterval(() => _getTime(), 1000);
};

export const Phone = function () {
	const wrapper = document.getElementById('appIntroOverlay');
	if (wrapper) {
		document.body.classList.add('lock-scroll');
	}

	time();
};
