import { getNavigatorAgent } from './utils.js';
import { API } from './api.js';
import { toast } from './utils.js';
import { closeModal } from './modal.js';

export const SupportForm = function () {
	const submit = async function (event) {
		event.preventDefault();

		const data = { ...getNavigatorAgent() };
		const fields = event.currentTarget.querySelectorAll('input');

		fields.forEach((field) => (data[field.name] = field.value));

		const res = await API.Mailgun.send(data);

		if (!res || (res && !res.data) || (res && res.error)) {
			toast('error', res.error + '. Contact info@capitasnowboarding.com');
		}

		if (res && res.data) {
			toast('success', 'Support request recieved');
			closeModal(null, 'support-modal');
			fields.forEach((field) => {
				if (field.name !== 'serialnumber') field.value = '';
			});
		}
	};

	window.submitSupportForm = submit;
};
