import React, { useState, useEffect, useRef, Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import Navigation from '../../components/Navigation';
import Table from '../../components/Table';
import { toast } from '../../utils.js';
import AddIcon from '../../components/icons/Add.js';
import Modal from '../../components/Modal';
import FormSnowboardMetadata from './FormSnowboardMetadata.js';

let timeout = null;
const schema = {
  _3d_board_construction_image: '',
  _3d_board_construction_text: '',
  background_image_url: '',
  camber_description: '',
  camber_image: '',
  camber_type: '',
  catalog_id: '',
  description: '',
  flex_description: '',
  flex_graphic: '',
  handle: '',
  id: '',
  import_id: null,
  item_code: '',
  rating_all_mtn: '',
  rating_park: '',
  rating_performance: '',
  rating_powder: '',
  rating_urban: '',
  rider_type: '',
  rider_type_title: '',
  season: '',
  size: '',
  subheading: '',
  title: '',
  type: '',
  url: '',
  variant_barcode: '',
  variant_id: null,
  variant_image_url: '',
  variant_sku: '',
  video_id: ''
};

// Get Product Codes
const Wrapper = function () {
  const refImport = useRef(null);
  const [snowboards, setSnowboards] = useState(null);
  const [snowboard, setSnowboard] = useState(Object.assign({}, schema));

  const createTableRow = function (item) {
    return {
      data: [
        { key: item.id },
        item.title,
        item.season,
        item.size,
        item.item_code,
        '',
        {
          button: {
            text: 'Edit',
            callback: () => setSnowboard(item)
          }
        }
      ]
    };
  };

  const closeModal = function () {
    setSnowboard(Object.assign({}, schema));
  };

  const setField = function (value, field) {
    const state = Object.assign({}, snowboard);
    state[field] = value;
    setSnowboard(state);
  };

  const submitMetadata = async function (e) {
    e.preventDefault();

    const data = Object.assign({}, snowboard);

    if (!data.import_id) {
      return toast('error', 'Import ID is rquired');
    }

    if (!data.variant_id) {
      return toast('error', 'Variant ID is rquired');
    }

    if (data.id === 'new' || data.id === '') delete data.id;
    if (data.import_id) data.import_id = parseInt(data.import_id);
    if (data.variant_id) data.variant_id = parseInt(data.variant_id);

    const raw = await fetch('/api/snowboard', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    });

    setSnowboard(Object.assign({}, schema));

    if (!raw) return;

    const res = await raw.json();

    if (res && res.error) {
      return toast('error', res.error);
    }

    if (res && res.data) {
      const state = Object.assign([], snowboards);
      state.splice(0, 0, createTableRow(res.data));
      setSnowboards(state);
    }
  };

  const updateMetadata = async function (e) {
    e.preventDefault();

    const data = Object.assign({}, snowboard);

    if (!data.import_id) {
      return toast('error', 'Import ID is rquired');
    }

    if (!data.variant_id) {
      return toast('error', 'Variant ID is rquired');
    }

    if (data.import_id) data.import_id = parseInt(data.import_id);
    if (data.variant_id) data.variant_id = parseInt(data.variant_id);

    const raw = await fetch('/api/snowboard/' + data.id, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    });

    setSnowboard(Object.assign({}, schema));

    if (!raw || (raw && !raw.ok)) return;

    const res = await raw.json();

    if (res && res.error) {
      return toast('error', res.error);
    }

    if (res && res.data) {
      const state = Object.assign([], snowboards);

      const _state = state.map((snowboard, index) => {
        if (snowboard.data[0].key === res.data.id) {
          return createTableRow(res.data);
        }
        return snowboard;
      });

      setSnowboards(_state);
    }
  };

  const getAllMetaData = async function () {
    const raw = await fetch('/api/snowboards');
    if (!raw) return;

    const res = await raw.json();

    if (res && res.error) {
      return toast('error', res.error);
    }

    if (res && res.data) {
      let rows = [];
      const data = res.data.map((item) => ({
        data: Object.values(item)
      }));

      res.data.forEach((item) => {
        rows.push(createTableRow(item));
      });

      setSnowboards(rows);
    }
  };

  const getMetadata = async function (value) {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(async () => {
      if (!value || (value && value === '')) {
        return getAllMetaData();
      }

      const raw = await fetch('/api/snowboards?title=' + value);
      if (!raw) return;

      const res = await raw.json();

      if (res && res.error) {
        return toast('error', res.error);
      }

      if (res && res.data) {
        const rows = [];
        res.data.forEach((row) => {
          rows.push(createTableRow(row));
        });
        setSnowboards(rows);
      }

      clearTimeout(timeout);
      timeout = null;
    }, 500);
  };

  const importTSV = async function (e) {
    const file = refImport.current.files[0];

    if (!file) return;

    const data = new FormData();
    // 'upload-file' - needs to match on server in multer.single
    data.append('upload-file', file);

    const raw = await fetch('/api/snowboards/import', {
      method: 'POST',
      body: data
    });

    if (!raw || (raw && !raw.ok)) return;

    const res = await raw.json();

    if (res && res.error) {
      return toast('error', res.error);
    }

    if (res && res.data) {
      toast('success', 'Upload complete');
    }
  };

  useEffect(() => {
    getAllMetaData();
  }, []);

  let hasId = false;
  if (snowboard.id && snowboard.id !== 'new') hasId = true;

  return (
    <Fragment>
      <section>
        <div className="container container--padding container--lg admin__tabs">
          <div className="row">
            <button
              className="btn btn--rounded btn--black"
              onClick={() => setSnowboard({ ...snowboard, id: 'new' })}
            >
              <span>New</span>
              <AddIcon />
            </button>
            <a href="/admin/downloadSnowboardMetaTSV" className="btn btn--rounded btn--black">
              Export TSV
            </a>
            <div className="import btn btn--rounded btn--black">
              <form encType="multipart/form-data">
                <input
                  ref={refImport}
                  type="file"
                  name="upload-file"
                  onChange={(e) => importTSV(e)}
                  accept=".tsv"
                />
              </form>
              <span>Import TSV</span>
            </div>
          </div>
        </div>
      </section>
      <section className="admin__snowboards">
        <div className="container container--padding container--lg">
          <Table
            withFilter={true}
            filterOverride={getMetadata}
            headers={['Title', 'Season', 'Size', 'Product Code', '']}
            data={snowboards}
          />
        </div>
        <Modal active={snowboard.id ? true : false} closeModal={closeModal}>
          <FormSnowboardMetadata
            update={hasId}
            onChange={setField}
            onSubmit={!hasId ? submitMetadata : updateMetadata}
            snowboard={snowboard}
          />
        </Modal>
      </section>
    </Fragment>
  );
};

const AdminMetaData = function () {
  const root = document.getElementById('admin-snowboards-meta-data');
  if (!root) return;

  const app = ReactDOM.createRoot(document.getElementById('admin-snowboards-meta-data'));

  app.render(
    <Fragment>
      <Navigation />
      <Wrapper />
    </Fragment>
  );
};

export default AdminMetaData;
