const SnowboardMetadata = function (props) {
	return (
		<form className="form" onSubmit={(e) => props.onSubmit(e)}>
			<fieldset className="row">
				<legend>Board Description</legend>
				<div className="form__input col-xs-12 col-md-5">
					<label>Title:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'title')}
						defaultValue={props.snowboard.title}
					/>
				</div>
				<div className="form__input col-xs-12 col-md-5 col-md-offset-2">
					<label>Subheading:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'subheading')}
						defaultValue={props.snowboard.subheading}
					/>
				</div>
				<div className="form__input col-xs-12 col-md-5">
					<label>URL:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'url')}
						defaultValue={props.snowboard.url}
					/>
				</div>
				<div className="form__input col-xs-12 col-md-5 col-md-offset-2">
					<label>Handle:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'handle')}
						defaultValue={props.snowboard.handle}
					/>
				</div>
				<div className="form__input col-xs-12">
					<label>Description:</label>
					<input
						className="form-control"
						type="textarea"
						onChange={(e) => props.onChange(e.target.value, 'description')}
						defaultValue={props.snowboard.description}
					/>
				</div>
			</fieldset>
			<fieldset className="row">
				<legend>Store Values</legend>
				<div className="form__input col-xs-12 col-md-5">
					<label>Catalog ID:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'catalog_id')}
						defaultValue={props.snowboard.catalog_id}
					/>
				</div>
				<div className="form__input col-xs-12 col-md-5 col-md-offset-2">
					<label>Import ID:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'import_id')}
						defaultValue={props.snowboard.import_id}
					/>
				</div>
				<div className="form__input col-xs-12 col-md-5">
					<label>Item Code:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'item_code')}
						defaultValue={props.snowboard.item_code}
					/>
				</div>
				<div className="form__input col-xs-12 col-md-5 col-md-offset-2">
					<label>Type:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'type')}
						defaultValue={props.snowboard.type}
					/>
				</div>
				<div className="form__input col-xs-12">
					<label>Season:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'season')}
						defaultValue={props.snowboard.season}
					/>
				</div>
			</fieldset>
			<fieldset className="row">
				<legend>Media</legend>
				<div className="form__input col-xs-12 col-md-5">
					<label>Background image URL:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'background_image_url')}
						defaultValue={props.snowboard.background_image_url}
					/>
				</div>
				<div className="form__input col-xs-12 col-md-5 col-md-offset-2">
					<label>Video ID:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'video_id')}
						defaultValue={props.snowboard.video_id}
					/>
				</div>
				<div className="form__input col-xs-12 col-md-5">
					<label>3D Board Construction Image:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, '_3d_board_construction_imag')}
						defaultValue={props.snowboard._3d_board_construction_image}
					/>
				</div>
				<div className="form__input col-xs-12 col-md-5 col-md-offset-2">
					<label>3D Board Construction Text:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, '_3d_board_construction_text')}
						defaultValue={props.snowboard._3d_board_construction_text}
					/>
				</div>
			</fieldset>
			<fieldset className="row">
				<legend>Variant</legend>
				<div className="form__input col-xs-12 col-md-5">
					<label>Barcode:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'variant_barcode')}
						defaultValue={props.snowboard.variant_barcode}
					/>
				</div>
				<div className="form__input col-xs-12 col-md-5 col-md-offset-2">
					<label>ID:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'variant_id')}
						defaultValue={props.snowboard.variant_id}
					/>
				</div>
				<div className="form__input col-xs-12 col-md-5">
					<label>Image URL:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'variant_image_url')}
						defaultValue={props.snowboard.variant_image_url}
					/>
				</div>
				<div className="form__input col-xs-12 col-md-5 col-md-offset-2">
					<label>SKU:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'variant_sku')}
						defaultValue={props.snowboard.variant_sku}
					/>
				</div>
				<div className="form__input col-xs-12">
					<label>Size:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'size')}
						defaultValue={props.snowboard.size}
					/>
				</div>
			</fieldset>
			<fieldset className="row">
				<legend>Rider</legend>
				<div className="form__input col-xs-12 col-md-5">
					<label>Type:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'rider_type')}
						defaultValue={props.snowboard.rider_type}
					/>
				</div>
				<div className="form__input col-xs-12 col-md-5 col-md-offset-2">
					<label>Type Title:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'rider_type_title')}
						defaultValue={props.snowboard.rider_type_title}
					/>
				</div>
			</fieldset>
			<fieldset className="row">
				<div className="form__input col-xs-12 col-md-5">
					<legend>Rating</legend>
					<label>Park:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'rating_park')}
						defaultValue={props.snowboard.rating_park}
					/>
				</div>
				<div className="form__input col-xs-12 col-md-5 col-md-offset-2">
					<label>Urban:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'rating_urban')}
						defaultValue={props.snowboard.rating_urban}
					/>
				</div>
				<div className="form__input col-xs-12 col-md-5">
					<label>All Mountain:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'rating_all_mtn')}
						defaultValue={props.snowboard.rating_all_mtn}
					/>
				</div>
				<div className="form__input col-xs-12 col-md-5 col-md-offset-2">
					<label>Powder:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'rating_powder')}
						defaultValue={props.snowboard.rating_powder}
					/>
				</div>
				<div className="form__input col-xs-12">
					<label>Performance:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'rating_performance')}
						defaultValue={props.snowboard.rating_performance}
					/>
				</div>
			</fieldset>
			<fieldset className="row">
				<legend>Camber</legend>
				<div className="form__input col-xs-12 col-md-5">
					<label>Type:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'camber_type')}
						defaultValue={props.snowboard.camber_type}
					/>
				</div>
				<div className="form__input col-xs-12 col-md-5 col-md-offset-2">
					<label>Description:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'camber_description')}
						defaultValue={props.snowboard.camber_description}
					/>
				</div>
				<div className="form__input col-xs-12">
					<label>Image:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'camber_image')}
						defaultValue={props.snowboard.camber_image}
					/>
				</div>
			</fieldset>
			<fieldset className="row">
				<legend>Flex</legend>
				<div className="form__input col-xs-12 col-md-5">
					<label>Description:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'flex_description')}
						defaultValue={props.snowboard.flex_description}
					/>
				</div>
				<div className="form__input col-xs-12 col-md-5 col-md-offset-2">
					<label>Graphic:</label>
					<input
						className="form-control"
						type="text"
						onChange={(e) => props.onChange(e.target.value, 'flex_graphic')}
						defaultValue={props.snowboard.flex_graphic}
					/>
				</div>
			</fieldset>
			<div className="row end-xs">
				<button type="submit" className="btn btn-primary">
					{props.update ? 'Update' : 'Save'}
				</button>
			</div>
		</form>
	);
};

export default SnowboardMetadata;
