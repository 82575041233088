const Snowboards = function () {
	return (
		<svg viewBox="0 0 512.001 512.001">
			<path
				d="M490.541,78.789l-57.328-57.329c-14.35-14.349-33.705-21.955-54.475-21.434c-21.978,0.557-43.235,10.455-58.32,27.154
			c-41.411,45.841-99.619,109.33-141.752,151.464C136.53,220.78,73.029,278.998,27.179,320.418
			c-16.698,15.086-26.596,36.342-27.153,58.32c-0.528,20.779,7.085,40.125,21.434,54.473l57.329,57.329
			C92.674,504.425,111.23,512,131.241,512c0.687,0,1.38-0.009,2.07-0.027c22-0.572,43.264-10.497,58.343-27.23
			c41.335-45.873,99.451-109.399,141.581-151.53c10.23-10.23,22.363-22.036,36.062-35.094c5.061-4.824,5.253-12.836,0.429-17.897
			c-4.823-5.06-12.836-5.252-17.897-0.429c-13.846,13.197-26.125,25.147-36.498,35.519
			C272.817,357.827,214.38,421.7,172.845,467.797c-10.452,11.601-25.102,18.478-40.192,18.869
			c-13.788,0.356-26.558-4.623-35.961-14.026l-57.329-57.329c-9.395-9.394-14.377-22.154-14.027-35.928
			c0.382-15.077,7.241-29.72,18.816-40.177C90.22,297.59,154.058,239.06,196.569,196.549
			c42.508-42.51,101.029-106.337,142.636-152.396c10.456-11.575,25.1-18.434,40.175-18.816c13.761-0.351,26.534,4.632,35.93,14.027
			l57.328,57.328c9.404,9.403,14.385,22.176,14.027,35.962c-0.392,15.091-7.27,29.741-18.869,40.192
			c-21.29,19.182-41.577,37.651-60.299,54.894c-5.143,4.736-5.472,12.745-0.737,17.887s12.744,5.471,17.887,0.737
			c18.655-17.181,38.874-35.586,60.095-54.708c16.734-15.079,26.659-36.343,27.232-58.342
			C512.514,112.513,504.903,93.15,490.541,78.789z"
			/>
			<path
				d="M400.728,260.531c-0.165-0.81-0.405-1.608-0.722-2.367c-0.316-0.772-0.709-1.506-1.165-2.19
			c-0.456-0.696-0.987-1.342-1.57-1.924c-0.582-0.582-1.241-1.114-1.924-1.57c-0.696-0.456-1.43-0.848-2.19-1.165
			c-0.76-0.316-1.557-0.557-2.367-0.722c-1.633-0.329-3.317-0.329-4.95,0c-0.81,0.165-1.608,0.405-2.367,0.722
			s-1.494,0.709-2.19,1.165c-0.684,0.456-1.329,0.987-1.924,1.57c-0.582,0.582-1.101,1.228-1.57,1.924
			c-0.456,0.684-0.848,1.418-1.165,2.19c-0.316,0.76-0.557,1.557-0.722,2.367c-0.165,0.81-0.253,1.646-0.253,2.468
			s0.089,1.658,0.253,2.481c0.165,0.797,0.405,1.595,0.722,2.367c0.317,0.76,0.709,1.494,1.165,2.177
			c0.468,0.696,0.987,1.342,1.57,1.924c0.595,0.582,1.241,1.114,1.924,1.582c0.696,0.456,1.43,0.848,2.19,1.165
			c0.76,0.304,1.557,0.557,2.367,0.709c0.823,0.165,1.646,0.253,2.481,0.253c0.823,0,1.646-0.089,2.469-0.253
			c0.81-0.152,1.608-0.405,2.367-0.709c0.76-0.316,1.494-0.709,2.19-1.165c0.684-0.468,1.342-1,1.924-1.582
			c0.582-0.582,1.114-1.228,1.57-1.924c0.456-0.684,0.848-1.418,1.165-2.177c0.316-0.772,0.557-1.57,0.722-2.367
			c0.165-0.823,0.253-1.658,0.253-2.481S400.893,261.342,400.728,260.531z"
			/>
			<path
				d="M372.366,175.44l-35.805-35.805c-14.806-14.806-38.899-14.806-53.706,0c-14.807,14.807-14.807,38.901,0,53.708
			l35.804,35.804c7.404,7.403,17.129,11.104,26.853,11.104c9.726,0,19.45-3.701,26.854-11.106
			C387.172,214.339,387.172,190.247,372.366,175.44z M309.707,184.391l-8.951-8.951c-4.936-4.936-4.936-12.966,0-17.902
			c2.468-2.468,5.709-3.701,8.951-3.701c3.242,0,6.484,1.234,8.951,3.701l8.951,8.951L309.707,184.391z M354.463,211.244
			c-4.934,4.936-12.968,4.937-17.902,0l-8.951-8.951l17.902-17.902l8.951,8.951C359.398,198.278,359.398,206.31,354.463,211.244z"
			/>
			<path
				d="M229.148,318.657l-35.804-35.804c-14.807-14.806-38.901-14.806-53.708,0c-14.806,14.807-14.806,38.899,0,53.706
			l35.805,35.805c7.403,7.403,17.129,11.104,26.853,11.104s19.45-3.701,26.853-11.104
			C243.955,357.558,243.955,333.464,229.148,318.657z M166.489,327.609l-8.951-8.951c-4.936-4.936-4.936-12.968,0-17.902
			c4.934-4.936,12.966-4.937,17.902,0l8.951,8.951L166.489,327.609z M211.246,354.462c-4.936,4.934-12.966,4.934-17.903,0
			l-8.951-8.951l17.902-17.902l8.951,8.951C216.181,341.496,216.181,349.527,211.246,354.462z"
			/>
		</svg>
	);
};

export default Snowboards;
