export const Menu = function () {
	const navMenu = document.getElementById('menuNav');

	if (!navMenu) return;

	const menuBtn = document.getElementById('appMenuButton');

	if (!menuBtn) return;
	menuBtn.querySelector('button').addEventListener('click', () => {
		gsap.to(navMenu, {
			duration: 0.3,
			autoAlpha: 1,
		});
		document.body.classList.toggle('lock-scroll');
	});

	const closeButton = document.querySelector('.menuNav__closer button');
	if (!closeButton) return;

	closeButton.addEventListener('click', () => {
		gsap.to(navMenu, {
			duration: 0.3,
			autoAlpha: 0,
		});
		document.body.classList.toggle('lock-scroll');
	});
};
