import React, { useState, useEffect, useRef, Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import { toast } from '../../utils.js';

const ProductionChart = function (props) {
	const productionRef = useRef(null);
	const previousYearRef = useRef(null);
	const [stats, setStats] = useState(null);
	const [currentYear, setCurrentYear] = useState(null);
	const [previousYear, setPreviousYear] = useState(null);
	const [rogueYears, setRogueYears] = useState(null);

	// Sort stats by year
	const sortStats = function (data) {
		if (!data) return;

		const year = new Date().getFullYear();
		const current = data.filter((item) => item.year === year);
		const previous = data.filter((item) => item.year === year - 1);
		const rogue = data.filter((item) => item.year !== year && item.year !== year - 1);

		setCurrentYear(current);
		setPreviousYear(previous);
		setRogueYears(rogue);
	};

	const _yMin = function (arr) {
		if (!arr) return 10000;
		let num = Math.max(...arr) + 2000;
		if (num < 10) return Math.ceil((num + 1) / 10) * 10;
		if (num < 1000 || num > 100000) return Math.ceil((num + 1) / 100) * 100;
		if (num < 100000) return Math.ceil((num + 1) / 1000) * 1000;
	};

	// Intialize chart based on year
	const createChart = function () {
		const labels = [];
		const datasets = [];

		for (let i = 0; i < 52; i++) {
			labels.push('Week ' + (i + 1));
		}

		if (previousYear && previousYear.length) {
			const label = [];
			const data = previousYear.map((d, index) => {
				label.push('Week ' + (index + 1));
				return d.count;
			});

			datasets.push({
				data,
				label: previousYear[0].year,
				backgroundColor: '#000000'
			});
		}

		if (currentYear) {
			const date = new Date();
			const label = [];
			const data = currentYear.map((d, index) => {
				label.push('Week ' + (index + 1));
				return d.count;
			});

			datasets.push({
				data,
				label: date.getFullYear(),
				backgroundColor: '#35a2eb'
			});
		}

		const config = {
			type: 'bar',
			data: {
				labels,
				datasets
			},
			options: {
				responsive: true,
				plugins: {
					legend: {
						position: 'top',
						labels: {
							color: 'white'
						}
					},
					title: {
						display: true,
						text: 'CAPiTA Snowboard Manufacturing Over 1 Year',
						color: '#ffffff',
						align: 'start',
						padding: 15,
						font: { size: 22 }
					}
				},
				scales: {
					y: {
						ticks: {
							color: '#FFFFFF',
							textStrokeColor: '#FFFFFF'
						}
					},
					x: {
						ticks: {
							color: '#FFFFFF',
							textStrokeColor: '#FFFFFF'
						}
					}
				}
			}
		};

		if (previousYear || currentYear) {
			new Chart(productionRef.current, config);
		}
	};

	// Get init stats and sort by year
	useEffect(() => {
		(async () => {
			const raw = await fetch('/api/stats/produced-boards');

			const res = await raw.json();
			if (res && res.error) {
				return toast('error', res.error);
			}

			if (res && res.data) {
				setStats(res.data);
				sortStats(res.data);
			}
		})();
	}, []);

	// Rerender view as sorted stats come in
	useEffect(() => createChart(), [currentYear, previousYear]);

	return (
		<Fragment>
			<canvas width="600" height="300" ref={productionRef}></canvas>
		</Fragment>
	);
};

export default ProductionChart;
