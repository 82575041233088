const Imports = function () {
	return (
		<svg viewBox="0 0 490.8 490.8">
			<path
				d="M9.1,297.189c1,0.3,2.1,0.4,3.1,0.4c4.3,0,8.4-2.3,10.7-6.2c47-82.8,96-88.4,151.9-88.8v49.6c0,4.6,2.6,8.8,6.7,10.9
			c4.1,2.1,9,1.7,12.7-0.9l152.8-109.8c3.2-2.3,5.1-6,5.1-10s-1.9-7.7-5.1-10l-152.8-109.2c-3.7-2.7-8.6-3-12.7-0.9
			c-4.1,2.1-6.6,6.3-6.6,10.9v50.4c-70.2,6-120.1,33.4-148.2,81.5c-23.9,40.8-26.7,88.2-26.7,120.3
			C0,290.889,3.8,295.789,9.1,297.189z M187.7,107.289c6.5-0.3,11.6-5.7,11.6-12.2v-38l119.5,85.5l-119.5,85.8v-38
			c0-3.2-1.3-6.4-3.6-8.7s-5.4-3.6-8.7-3.6l0,0c-53.8,0-109.1,0-159.9,63.1C35.9,177.989,69.7,113.189,187.7,107.289z"
			/>
			<path
				d="M403.9,154.889h62.4v290.4H119.9v-175.9c0-6.8-5.5-12.3-12.3-12.3s-12.2,5.6-12.2,12.3v188.2c0,6.8,5.5,12.3,12.3,12.3
			h370.8c6.8,0,12.3-5.5,12.3-12.3v-315c0-6.8-5.5-12.3-12.3-12.3h-74.7c-6.8,0-12.3,5.5-12.3,12.3
			C391.6,149.389,397.1,154.889,403.9,154.889z"
			/>
		</svg>
	);
};

export default Imports;
