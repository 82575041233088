import Skull from '../icons/Skull.js';

const Input = function (props) {
	return (
		<div
			className={`form__input form__input--reduced${
				props.withIcon ? ' form__input--with-icon' : ''
			}`}
		>
			{props.withIcon && <Skull />}
			<input
				type={props.type ? props.type : 'text'}
				defaultValue={props.defaultValue}
				placeholder={props.placeholder}
				onChange={(e) => props.onChange(e.target.value)}
			/>
		</div>
	);
};

export default Input;
