import React, { useState, useEffect, Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import BeatLoader from 'react-spinners/BeatLoader';
import dayjs from 'dayjs';
import Input from '../Input';
import { toast } from '../../utils.js';

const Table = function (props) {
  const [search, setSearchParams] = useState('');
  const [headers, setHeaders] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (props && props.headers) setHeaders(props.headers);
  }, [props.headers, headers]);

  useEffect(() => {
    if (props && props.data) setData(props.data);
  }, [props.data, data]);

  const filter = (value) => {
    const _value = value.toLowerCase();
    setSearchParams(_value);
  };

  const parseKey = function (items, index) {
    const item = items.find((item) => {
      if (typeof item === 'object' && item.key) return item;
    });

    if (item) return item.key;

    return index;
  };

  const parseItemObject = function (item) {
    if (!item) return '';

    if (item && item.button) {
      return (
        <button className="button btn--black" type="button" onClick={item.button.callback}>
          {item.button.text}
        </button>
      );
    }

    if (item && item.link) {
      return (
        <a href={item.link.url} className="button btn--black">
          {item.link.text}
        </a>
      );
    }

    if (item && item.date) {
      return dayjs(item.id).format('DD-MM-YY');
    }

    if (item && item.email) {
      return <a href={`mailto: ${item.email}`}>{item.email}</a>;
    }

    return '';
  };

  if (!data) {
    return (
      <div className="row center-xs middle-xs">
        <BeatLoader color="#ffffff" loading={true} size={15} aria-label="Loading Spinner" />
      </div>
    );
  }

  return (
    <Fragment>
      {props.withFilter && (
        <div className="row form">
          <Input
            type="text"
            defaultValue={search}
            placeholder="Filter"
            onChange={props.filterOverride ? props.filterOverride : setSearchParams}
            withIcon={true}
          />
        </div>
      )}
      <div className="row">
        <table>
          {headers && (
            <thead>
              <tr>
                {headers.map((item, index) => {
                  return <th key={item.key || index}>{item}</th>;
                })}
              </tr>
            </thead>
          )}
          <tbody>
            {data.map((items, index) => {
              if (!search) {
                return (
                  <tr id={parseKey(items.data, index)} key={parseKey(items.data, index)}>
                    {items.data.map((item, i) => {
                      if (typeof item === 'string') return <td key={i}>{item}</td>;
                      if (typeof item === 'object' && !item.key)
                        return <td key={i}>{parseItemObject(item)}</td>;
                    })}
                  </tr>
                );
              }

              if (search && items.data.join(' ').indexOf(search) > -1) {
                return (
                  <tr id={parseKey(items.data, index)} key={parseKey(items.data, index)}>
                    {items.data.map((item, i) => {
                      if (typeof item === 'string') return <td key={i}>{item}</td>;
                      if (typeof item === 'object' && !item.key)
                        return <td key={i}>{parseItemObject(item)}</td>;
                    })}
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default Table;
