import React, { useState, useEffect, Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import Navigation from '../../components/Navigation';
import Table from '../../components/Table';

const SupportRequests = function () {
  const [requests, setRequests] = useState(null);

  useEffect(() => {
    (async () => {
      const raw = await fetch('/api/support');
      if (!raw) return;
      const res = await raw.json();
      if (res && res.error) {
        return toast('error', res.error);
      }

      if (res && res.data) {
        let rows = [];
        const data = res.data.map((item) => ({
          data: Object.values(item),
        }));

        res.data.forEach((item) => {
          rows.push({
            data: [
              { key: item.id },
              { date: item.id },
              item.firstname,
              item.lastname,
              { email: item.email },
              item.nfc_uid,
              item.browser + ' / ' + item.device,
            ],
          });
        });

        setRequests(rows);
      }
    })();
  }, []);

  return (
    <section id="support-requests" className="admin__support-requests">
      <div className="container container--padding container--lg">
        <Table
          withFilter={true}
          headers={['Date', 'First Name', 'Last Name', 'Email', 'NFC UID', 'Browser/Device']}
          data={requests}
        />
      </div>
    </section>
  );
};

const Wrapper = function () {
  return (
    <Fragment>
      <Navigation />
      <SupportRequests />
    </Fragment>
  );
};

const AdminSupportRequests = function () {
  const root = document.getElementById('admin-support-requests');
  if (!root) return;

  const app = ReactDOM.createRoot(document.getElementById('admin-support-requests'));

  app.render(<Wrapper />);
};

export default AdminSupportRequests;
