export const BackToTop = function () {
	const appContent = document.getElementById('appContent');
	const main = document.querySelector('main');
	const btn = document.getElementById('back-to-top');
	if (!btn || !main || !appContent) return;

	const _active = function () {
		if (main.getBoundingClientRect().top < -100) {
			btn.classList.add('active');
		} else {
			btn.classList.remove('active');
		}
	};

	const _scrollTo = function (el) {
		el.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	};

	if (Math.max(window.innerWidth) < 767) {
		btn.addEventListener('click', () => _scrollTo(window));
		window.addEventListener('scroll', () => _active());
	} else {
		btn.addEventListener('click', () => _scrollTo(appContent));
		appContent.addEventListener('scroll', () => _active());
	}
};
