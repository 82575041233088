import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import Navigation from '../../components/Navigation';
import ProductionChart from '../../components/ProductionChart';
import RegistrationStats from '../../components/RegistrationStats';

const AdminOverview = function () {
	const root = document.getElementById('admin-overview');
	if (!root) return;

	const app = ReactDOM.createRoot(document.getElementById('admin-overview'));

	app.render(
		<Fragment>
			<Navigation />
			<section id="admin-overview" className="admin__overview">
				<div className="container container--padding container--xxl">
					<div className="row middle-xs">
						<div className="production-stats col-xs-12 col-lg-8 center-xs">
							<ProductionChart />
						</div>
						<div className="registration-stats col-xs-12 col-lg-4 center-xs middle-xs">
							<RegistrationStats />
						</div>
					</div>
				</div>
			</section>
		</Fragment>
	);
};

export default AdminOverview;
