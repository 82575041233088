import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';

// Vendor
import { Tooltip } from 'react-tooltip';

// Markup
import Capita from '../icons/Capita.js';
import Dashboard from '../icons/Dashboard.js';
import Imports from '../icons/Imports.js';
import Register from '../icons/Register.js';
import Snowboard from '../icons/Snowboards.js';
import ProductCodes from '../icons/ProductCodes.js';
import Support from '../icons/Support.js';

const links = [
	{ text: 'Overview', url: '/admin/overview', icon: () => <Dashboard /> },
	{ text: 'Imports', url: '/admin/nfclist', icon: () => <Imports /> },
	{ text: 'Registration', url: '/admin/registrationlist', icon: () => <Register /> },
	{ text: 'Product Codes', url: '/admin/prodcodes', icon: () => <ProductCodes /> },
	{ text: 'Snowboards', url: '/admin/snowboards', icon: () => <Snowboard /> },
	{ text: 'Support Requests', url: '/admin/support-requests', icon: () => <Support /> },
];

const Navigation = function () {
	return (
		<header>
			<div className="container container--padding container--xxl">
				<div className="row">
					<div className="logo col-xs-12 col-md-3">
						<Capita />
					</div>
					<nav className="admin__nav-items col-xs-12 center-xs middle-xs col-md-9 end-md">
						<ul>
							{links.map((link, index) => {
								return (
									<li key={index}>
										<a
											id={link.text}
											data-tooltip-content={link.text}
											title={link.text}
											href={link.url}
										>
											{link.icon()}
										</a>
										<Tooltip anchorId={link.text} />
									</li>
								);
							})}
						</ul>
					</nav>
				</div>
			</div>
		</header>
	);
};

export default Navigation;
