import { asyncTimeout, getCSSVariable } from './utils.js';

const delay = parseInt(getCSSVariable('--animate-duration'));

const escapeKeyClose = function (e) {
	if (e.keyCode === 27) {
		const modals = document.querySelectorAll('[data-modal]');

		modals.forEach((modal) => {
			const id = modal.getAttribute('data-modal');
			closeModal(null, id);
		});
	}
};

export const closeModal = async function (event, id) {
	const modal = document.querySelector(`[data-modal="${id}"`);
	const dialog = document.querySelector(`[data-modal-dialog="${id}"]`);

	dialog.classList.add('animate__fadeOutUp');
	await asyncTimeout(delay);
	modal.classList.add('animate__fadeOut');
	await asyncTimeout(delay);
	modal.classList.remove('animate__fadeIn', 'animate__fadeOut');
	dialog.classList.remove('animate__fadeInDown', 'animate__fadeOutUp');
	document.removeEventListener('keyup', escapeKeyClose);
};

export const Modal = function () {
	const openModal = async function (event, id) {
		const modal = document.querySelector(`[data-modal="${id}"`);
		const dialog = document.querySelector(`[data-modal-dialog="${id}"]`);

		modal.classList.add('animate__fadeIn');
		await asyncTimeout(delay);
		dialog.classList.add('animate__fadeInDown');
		document.addEventListener('keyup', escapeKeyClose);
	};

	window.openModal = openModal;
	window.closeModal = closeModal;
};
