/**
 * Check if body has class name
 * @param { string } page_class - class name to check for
 * @return { bool } boolean if class name exists or not
 * */
export const _isPageClass = function (page_class) {
	if (document.body.className.indexOf(page_class) > -1) {
		return true;
	} else {
		return false;
	}
};

/**
 * Creates aribtrary wait time based on miliseconds
 * @param { int } milliseconds
 * @return { promise } resolves once time has been reached
 * */
export const asyncTimeout = function (ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Gets CSS variables for usage
 * @param { string } variable name
 * */
export const getCSSVariable = function (variable) {
	return getComputedStyle(document.body).getPropertyValue(variable);
};

export const toast = function (type, text) {
	if (!text) return;

	let duration = 5000;
	if (type === 'error') duration = 10000;

	if (!type) {
		Toastify({
			text,
			duration,
		}).showToast();
		return;
	}

	Toastify({
		text,
		className: 'toast--' + type,
		duration,
	}).showToast();
};

/**
 * Gets browser agent type
 * @return { object } { device, browser }
 * */
export const getNavigatorAgent = function () {
	const userAgent = navigator.userAgent;
	let device = null;
	let browser = null;

	if (userAgent.match(/Android/i)) device = 'android';
	if (userAgent.match(/BlackBerry/i)) device = 'blackberry';
	if (userAgent.match(/iPhone|iPad|iPod/i)) device = 'ios';
	if (userAgent.match(/Opera Mini/i)) device = 'opera-mini';
	if (userAgent.match(/IEMobile/i)) device = 'ie-mobile';
	if (userAgent.match(/IEMobile/i)) device = 'ie-mobile';

	if (userAgent.indexOf('chrome') > -1 || userAgent.indexOf('Chrome') > -1) {
		browser = 'chrome';
		return { browser, device };
	}
	if (userAgent.indexOf('firefox') > -1 || userAgent.indexOf('Firefox') > -1) {
		browser = 'firefox';
		return { browser, device };
	}
	if (userAgent.indexOf('safari') > -1 || userAgent.indexOf('Safari') > -1) {
		browser = 'safari';
		return { browser, device };
	}
	if (userAgent.indexOf('opr') > -1 || userAgent.indexOf('Opr') > -1) {
		browser = 'opera';
		return { browser, device };
	}
	if (
		userAgent.indexOf('edg') > -1 ||
		userAgent.indexOf('edge') > -1 ||
		userAgent.indexOf('Edg') > -1 ||
		userAgent.indexOf('Edge') > -1
	) {
		browser = 'edge';
		return { browser, device };
	}
	if (userAgent.indexOf('trident') > -1 || userAgent.indexOf('Trident') > -1) {
		browser = 'internet-explorer';
		return { browser, device };
	}

	return { device, browser };
};
