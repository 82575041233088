export const Reviews = async function () {
	const reviewsEl = document.getElementById('reviews');

	if (!reviewsEl) return;

	const raw = await fetch('/api/reviews/' + reviewsEl.dataset.productId, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	});

	if (!raw) return;

	const res = await raw.json();

	if (!res || res.data.length === 0) return;

	for (let r = 0; r < res.data.length; r++) {
		let thisData = res.data[r];
		let div = document.createElement('div');
		div.classList.add('review');

		let starWrapper = document.createElement('div');
		starWrapper.classList.add('review__rating-wrapper');

		for (let s = 0; s < thisData['reviewRating']; s++) {
			let iItem = document.createElement('i');
			iItem.classList.add('fa');
			iItem.classList.add('fa-star');
			iItem.classList.add('fa-2');
			iItem.style.color = 'red';
			iItem.setAttribute('aria-hidden', 'false');
			starWrapper.append(iItem);
		}

		div.append(starWrapper);

		let headerItem = document.createElement('h4');
		headerItem.innerText = thisData['reviewTitle'];
		div.append(headerItem);

		let reviewItem = document.createElement('div');
		reviewItem.classList.add('review__message');
		reviewItem.innerText = thisData['reviewMessage'];
		div.append(reviewItem);

		let tagLineItem = document.createElement('div');
		tagLineItem.classList.add('review__credit');
		tagLineItem.innerHTML =
			"<span class='review__author'>" +
			thisData['author'] +
			"</span> on <span class='review__date'>" +
			thisData['reviewDate'] +
			'</span>';

		div.append(tagLineItem);

		reviewsEl.append(div);
	}
};
