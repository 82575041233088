import React, { useState, useEffect, Fragment, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import Navigation from '../../components/Navigation';
import Table from '../../components/Table';
import { toast, asyncTimeout } from '../../utils.js';

// Get CSVs
const CSVSection = function () {
  const [CSVs, setCSVs] = useState(null);

  useEffect(() => {
    (async () => {
      const raw = await fetch('/api/csv');
      if (!raw) return;

      const res = await raw.json();

      if (res && res.error) {
        return toast('error', res.error);
      }

      if (res && res.data) {
        const data = res.data.map((item) => ({
          data: Object.values(item),
        }));

        setCSVs(data);
      }
    })();
  }, []);

  return (
    <section id="csvlist" className="admin__nfclist">
      <div className="container container--padding container--lg">
        <Table
          withFilter={true}
          headers={['CSV File', 'Load Timestamp', '# Of Records']}
          data={CSVs}
        />
      </div>
    </section>
  );
};

// Get NFCs
const NFCSection = function () {
  const [NFCs, setNFCs] = useState(null);

  useEffect(() => {
    (async () => {
      const raw = await fetch('/api/csv?nfc=true');
      if (!raw) return;

      const res = await raw.json();

      if (res && res.error) {
        return toast('error', res.error);
      }

      if (res && res.data) {
        const rows = [];

        res.data.forEach((item) => {
          rows.push({
            data: [item.filename, item.nfc_uid, item.customer, item.prod_code, item.prod_desc],
          });
        });

        setNFCs(rows);
      }
    })();
  }, []);

  return (
    <section id="nfclist" className="admin__nfclist">
      <div className="container container--padding container--lg">
        <Table
          withFilter={true}
          headers={['CSV File', 'NFC UID', 'Customer', 'Product Code', 'Product Desc']}
          data={NFCs}
        />
      </div>
    </section>
  );
};

const Wrapper = function () {
  const refUploadBtn = useRef(null);
  const [table, setTable] = useState('imported');

  const uploadCSV = async function (e) {
    e.preventDefault();

    const file = refUploadBtn.current.files[0];
    if (!file || (file && file.type !== 'text/csv')) {
      refUploadBtn.current.value = '';
      return toast('error', 'Missing or incorrect file type');
    }

    const csv = new FormData();
    csv.append('file', file);

    toast('info', 'Uploading!!');
    const raw = await fetch('/api/csv', {
      method: 'POST',
      body: csv,
    });

    refUploadBtn.current.value = '';

    if (!raw) {
      return toast('error', res.error);
    }

    const res = await raw.json();

    if (res && res.error) {
      return toast('error', res.error);
    }

    if (res && res.data) {
      toast('success', 'Upload complete!');
      await asyncTimeout(3000);
      window.location.reload();
    }
  };

  return (
    <Fragment>
      <Navigation />
      <section className="admin__nfclist">
        <div className="container container--padding container--lg admin__tabs">
          <div className="row">
            <button
              type="button"
              className={`btn btn--rounded${table === 'imported' ? ' btn--black' : ''}  `}
              onClick={() => setTable('imported')}
            >
              Imported
            </button>
            <button
              type="button"
              className={`btn btn--rounded${table === 'nfc' ? ' btn--black' : ''}  `}
              onClick={() => setTable('nfc')}
            >
              NFC
            </button>
            <div className="btn btn--rounded btn--upload">
              Upload CSV
              <input
                ref={refUploadBtn}
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(e) => uploadCSV(e)}
              />
            </div>
          </div>
        </div>
      </section>
      {table === 'imported' && <CSVSection />}
      {table === 'nfc' && <NFCSection />}
    </Fragment>
  );
};

const AdminNFCList = function () {
  const root = document.getElementById('admin-nfclist');
  if (!root) return;

  const app = ReactDOM.createRoot(document.getElementById('admin-nfclist'));

  app.render(<Wrapper />);
};

export default AdminNFCList;
