import { _isPageClass } from './utils.js';
import { Overlay } from './overlay.js';
import { PentaGraphic } from './pentagraphic.js';
import { Reviews } from './reviews.js';
import { Video } from './video.js';
import { BackToTop } from './back-to-top.js';
import { TechInfo } from './tech-info.js';
import { Menu } from './menu.js';
import { Phone } from './phone.js';
import { Modal } from './modal.js';
import { SupportForm } from './support-form.js';
import AdminOverview from './views/AdminOverview';
import AdminNFCList from './views/AdminNFCList';
import AdminRegistrations from './views/AdminRegistrations';
import AdminProductCodes from './views/AdminProductCodes';
import AdminMetaData from './views/AdminMetaData';
import AdminSupportRequests from './views/AdminSupportRequests';

window.onload = function () {
  Phone();
  Overlay();
  PentaGraphic();
  Reviews();
  Video();
  BackToTop();
  TechInfo();
  Menu();
  Modal();
  SupportForm();
  AdminOverview();
  AdminNFCList();
  AdminRegistrations();
  AdminProductCodes();
  AdminMetaData();
  AdminSupportRequests();
};
